import React from 'react';
import './LetsTalk.css';

function LetsTalk(){
    return(
        <div className='lets-talk-main-div'>
            <div className='lets-talk-main-center'>
                Error
            </div>
        </div>
    );
}

export default LetsTalk;