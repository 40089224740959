import React from 'react';
import './Footer.css';


function Footer(){
    return(
    <div className="footer-background">
            <div className="footer-right-text">@sanjumahto3034 v1.0.0</div>
        </div>
    );
}
export default Footer;